import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SubscriptionService } from '../../services/subscription/subscription.service';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionBusinessService {

  constructor(private subscriptionService: SubscriptionService) { }

  getFeaturePagination(paramsReq: any): Observable<any> {
    return new Observable((observer) => {
      this.subscriptionService.getFeaturePagination(paramsReq).subscribe({
        next: (response) => {
          try {
            observer.next(response);
          }
          catch (e) {
            observer.error(e);
          }
        },
        error: (error) => {
          observer.error(error);
        }
      })
    })
  }
  
  getPlanPagination(paramsReq: any): Observable<any> {
    return new Observable((observer) => {
      this.subscriptionService.getPlanPagination(paramsReq).subscribe({
        next: (response) => {
          try {
            observer.next(response);
          }
          catch (e) {
            observer.error(e);
          }
        },
        error: (error) => {
          observer.error(error);
        }
      })
    })
  }

  getPublishedPlan(paramsReq: any): Observable<any> {
    return new Observable((observer) => {
      this.subscriptionService.getPublishedPlan(paramsReq).subscribe({
        next: (response) => {
          try {
            observer.next(response);
          }
          catch (e) {
            observer.error(e);
          }
        },
        error: (error) => {
          observer.error(error);
        }
      })
    })
  }

  getFeatureById(paramsReq:object):Observable<any>{
    return new Observable((observer) => {
      this.subscriptionService.getFeatureById(paramsReq).subscribe({
        next: (response) => {
          try {
            observer.next(response)
          }
          catch (e) {
            observer.error(e);
          }
        },
        error: (error) => {
          observer.error(error);
        }
      })
    })
  }

  getPlanByCliId(paramsReq:object):Observable<any>{
    return new Observable((observer) => {
      this.subscriptionService.getPlanByCliId(paramsReq).subscribe({
        next: (response) => {
          try {
            observer.next(response)
          }
          catch (e) {
            observer.error(e);
          }
        },
        error: (error) => {
          observer.error(error);
        }
      })
    })
  }
  getPlanById(paramsReq:object):Observable<any>{
    return new Observable((observer) => {
      this.subscriptionService.getPlanById(paramsReq).subscribe({
        next: (response) => {
          try {
            observer.next(response)
          }
          catch (e) {
            observer.error(e);
          }
        },
        error: (error) => {
          observer.error(error);
        }
      })
    })
  }

  getFeatureData():Observable<any>{
    return new Observable((observer) => {
      this.subscriptionService.getFeatureData().subscribe({
        next: (response) => {
          try {
            observer.next(response)
          }
          catch (e) {
            observer.error(e);
          }
        },
        error: (error) => {
          observer.error(error);
        }
      })
    })
  }

  getFeatureNameList():Observable<any>{
    return new Observable((observer) => {
      this.subscriptionService.getFeatureNameList().subscribe({
        next: (response) => {
          try {
            observer.next(response)
          }
          catch (e) {
            observer.error(e);
          }
        },
        error: (error) => {
          observer.error(error);
        }
      })
    })
  }
  getPlanList():Observable<any>{
    return new Observable((observer) => {
      this.subscriptionService.getPlanList().subscribe({
        next: (response) => {
          try {
            observer.next(response)
          }
          catch (e) {
            observer.error(e);
          }
        },
        error: (error) => {
          observer.error(error);
        }
      })
    })
  }
  getStatusList():Observable<any>{
    return new Observable((observer) => {
      this.subscriptionService.getStatusList().subscribe({
        next: (response) => {
          try {
            observer.next(response)
          }
          catch (e) {
            observer.error(e);
          }
        },
        error: (error) => {
          observer.error(error);
        }
      })
    })
  }
  updateFeature(data:any):Observable<any>{
    return new Observable((observer) => {
      this.subscriptionService.updateFeature(data).subscribe({
        next: (response) => {
          try {
            observer.next(response)
          }
          catch (e) {
            observer.error(e);
          }
        },
        error: (error) => {
          observer.error(error);
        }
      })
    })
  }
  updateClientwithPlan(data:any):Observable<any>{
    return new Observable((observer) => {
      this.subscriptionService.updateClientwithPlan(data).subscribe({
        next: (response) => {
          try {
            observer.next(response)
          }
          catch (e) {
            observer.error(e);
          }
        },
        error: (error) => {
          observer.error(error);
        }
      })
    })
  }
  updatePlan(data:any):Observable<any>{
    return new Observable((observer) => {
      this.subscriptionService.updatePlan(data).subscribe({
        next: (response) => {
          try {
            observer.next(response)
          }
          catch (e) {
            observer.error(e);
          }
        },
        error: (error) => {
          observer.error(error);
        }
      })
    })
  }
  createAddFeature(data: any): Observable<any> {
    return new Observable((observer) => {
      this.subscriptionService.createAddFeature(data).subscribe({
        next: (response) => {
          try {
            observer.next(response);
          }
          catch (e) {
            observer.error(e);
          }
        },
        error: (error) => {
          observer.error(error);
        }
      })
    })
  }

  getPlanNameByCountry(countryId):Observable<any>{
    return new Observable((observer)=>{
      this.subscriptionService.getPlanNameByCountry(countryId).subscribe({
        next:(response)=>{
          try{
            observer.next(response)
          }
          catch(e){
            observer.error(e);
          }
        }
      })
    })
  }

  getPricingList(paramsReq: any):Observable<any>{
    return new Observable((observer) => {
      this.subscriptionService.getPricingList(paramsReq).subscribe({
        next: (response) => {
          try {
            observer.next(response)
          }
          catch (e) {
            observer.error(e);
          }
        },
        error: (error) => {
          observer.error(error);
        }
      })
    })
  }
  
  createPlan(data: any): Observable<any> {
    return new Observable((observer) => {
      this.subscriptionService.createPlan(data).subscribe({
        next: (response) => {
          try {
            observer.next(response);
          }
          catch (e) {
            observer.error(e);
          }
        },
        error: (error) => {
          observer.error(error);
        }
      })
    })
  }

  getBillingPagination(paramsReq: any): Observable<any> {
    return new Observable((observer) => {
      this.subscriptionService.getBillingPagination(paramsReq).subscribe({
        next: (response) => {
          try {
            observer.next(response);
          }
          catch (e) {
            observer.error(e);
          }
        },
        error: (error) => {
          observer.error(error);
        }
      })
    })
  }

  getPartPlanInfo(paramsReq: any): Observable<any> {
    return new Observable((observer) => {
      this.subscriptionService.getPartPlanInfo(paramsReq).subscribe({
        next: (response) => {
          try {
            observer.next(response);
          }
          catch (e) {
            observer.error(e);
          }
        },
        error: (error) => {
          observer.error(error);
        }
      })
    })
  }

  getPlanFeature(paramsReq: any): Observable<any> {
    return new Observable((observer) => {
      this.subscriptionService.getPlanFeature(paramsReq).subscribe({
        next: (response) => {
          try {
            observer.next(response);
          }
          catch (e) {
            observer.error(e);
          }
        },
        error: (error) => {
          observer.error(error);
        }
      })
    })
  }

  getFeatureAccess(paramsReq: any): Observable<any> {
    return new Observable((observer) => {
      this.subscriptionService.getFeatureAccess(paramsReq).subscribe({
        next: (response) => {
          try {
            observer.next(response);
          }
          catch (e) {
            observer.error(e);
          }
        },
        error: (error) => {
          observer.error(error);
        }
      })
    })
  }

  downloadBillReport(data): Observable<Blob> {
    return new Observable((observer) => {
      this.subscriptionService.downloadBillReport(data).subscribe({
        next: (response) => {
          try {
            observer.next(response);
          }
          catch (e) {
            observer.error(e);
          }
        },
        error: (error) => {
          observer.error(error);
        }
      })
    })
  }

  createCard(data: any): Observable<any> {
    return new Observable((observer) => {
      this.subscriptionService.createCard(data).subscribe({
        next: (response) => {
          try {
            observer.next(response);
          }
          catch (e) {
            observer.error(e);
          }
        },
        error: (error) => {
          observer.error(error);
        }
      })
    })
  }

  createSubscription(data: any): Observable<any> {
    return new Observable((observer) => {
      this.subscriptionService.createSubscription(data).subscribe({
        next: (response) => {
          try {
            observer.next(response);
          }
          catch (e) {
            observer.error(e);
          }
        },
        error: (error) => {
          observer.error(error);
        }
      })
    })
  }

  getCardDetails(paramsReq: any): Observable<any> {
    return new Observable((observer) => {
      this.subscriptionService.getCardDetails(paramsReq).subscribe({
        next: (response) => {
          try {
            observer.next(response);
          }
          catch (e) {
            observer.error(e);
          }
        },
        error: (error) => {
          observer.error(error);
        }
      })
    })
  }

  updateAutoRenewal(data: any): Observable<any> {
    return new Observable((observer) => {
      this.subscriptionService.updateAutoRenewal(data).subscribe({
        next: (response) => {
          try {
            observer.next(response);
          }
          catch (e) {
            observer.error(e);
          }
        },
        error: (error) => {
          observer.error(error);
        }
      })
    })
  }

  deleteCard(data: any): Observable<any> {
    return new Observable((observer) => {
      this.subscriptionService.deleteCard(data).subscribe({
        next: (response) => {
          try {
            observer.next(response);
          }
          catch (e) {
            observer.error(e);
          }
        },
        error: (error) => {
          observer.error(error);
        }
      })
    })
  }

  renewSubscription(data: any): Observable<any> {
    return new Observable((observer) => {
      this.subscriptionService.renewSubscription(data).subscribe({
        next: (response) => {
          try {
            observer.next(response);
          }
          catch (e) {
            observer.error(e);
          }
        },
        error: (error) => {
          observer.error(error);
        }
      })
    })
  }

  upgradeSubscription(data:any):Observable<any>{
    return new Observable((observer) => {
      this.subscriptionService.upgradeSubscription(data).subscribe({
        next: (response) => {
          try {
            observer.next(response)
          }
          catch (e) {
            observer.error(e);
          }
        },
        error: (error) => {
          observer.error(error);
        }
      })
    })
  }

  getSubDetailByCli(paramsReq:object):Observable<any>{
    return new Observable((observer) => {
      this.subscriptionService.getSubDetailByCli(paramsReq).subscribe({
        next: (response) => {
          try {
            observer.next(response)
          }
          catch (e) {
            observer.error(e);
          }
        },
        error: (error) => {
          observer.error(error);
        }
      })
    })
  }

  publishConfirm(data:any):Observable<any>{
    return new Observable((observer) => {
      this.subscriptionService.publishConfirm(data).subscribe({
        next: (response:any) => {
          try {
            observer.next(response)
          }
          catch (e) {
            observer.error(e);
          }
        },
        error: (error) => {
          observer.error(error);
        }
      })
    })
  }
}
