import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserBusinessService } from 'src/app/core/business/user/user-business.service';
import { PreferenceInput } from 'src/app/core/models/user/preference-input';
import { User } from 'src/app/core/models/user/user';
import { UserInput } from 'src/app/core/models/user/user-input';
import { UserDTO } from 'src/app/domain/user/user.dto';

@Injectable({
  providedIn: 'root'
})
export class UserFacadeService {

  constructor(private userBusinessService: UserBusinessService) { }

  getStylist(id:number):Observable<User>{
    return this.userBusinessService.getStylist(id);
  }

  getUser(id:number):Observable<User>{
    return this.userBusinessService.getUser(id);
  }

  getCustomer(id:number):Observable<User>{
    return this.userBusinessService.getCustomer(id);
  }

  getClient(id:number):Observable<User>{
    return this.userBusinessService.getClient(id);
  }

  updateUser(data:UserInput):Observable<UserInput>{
    return this.userBusinessService.updateUser(data);
  }

  updateClient(data:UserInput):Observable<UserInput>{
    return this.userBusinessService.updateClient(data);
  }

  verifyEmailFirebase(authId:string):Observable<any>{
    return this.userBusinessService.verifyEmailFirebase(authId);
  }
  getUserByAuth(paramsReq: object):Observable<any>{
    return this.userBusinessService.getUserByAuth(paramsReq);
  }

  getPreference(data: PreferenceInput):Observable<any> {
    return this.userBusinessService.getPreference(data);
  }

  getCustomerName(paramsReq: object):Observable<any>{
    return this.userBusinessService.getCustomerName(paramsReq);
  }

  setMenuByRoleAccess():Observable<any>{
    return this.userBusinessService.setMenuByRoleAccess();
  }
}
