/**
 * This service file used to connect the profile changes while update profile image its update on top icon also.
 */

import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class SharedService {
  private profileImageUpdate = new Subject<void>();
  private variableReferenceId = new BehaviorSubject<any>({})
  variableReferencesViewEvents$: Observable<any> = this.variableReferenceId.asObservable();

  // BehaviorSubject will notify when there's a change
  private storageSub = new BehaviorSubject<boolean>(false);

  updateProfile() {
    this.profileImageUpdate.next();
  }

  getUpdateObservable() {
    return this.profileImageUpdate.asObservable();
  }

  // Trigger the change notification
  setStorageItem() {
    this.storageSub.next(true);
  }

  viewVariableReference(response){
    this.variableReferenceId.next({
      data: response
    })
  }
}
