import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SubscriptionBusinessService } from 'src/app/core/business/subscription/subscription-business.service';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionFacadesService {

  constructor(private subscriptionBusinessService: SubscriptionBusinessService) { }

  getFeaturePagination(data): Observable<any> {
    return this.subscriptionBusinessService.getFeaturePagination(data);
  }
  getPlanPagination(data): Observable<any> {
    return this.subscriptionBusinessService.getPlanPagination(data);
  }
  getFeatureById(paramsReq: object) {
    return this.subscriptionBusinessService.getFeatureById(paramsReq);
  }

  getPublishedPlan(paramsReq: any): Observable<any> {
    return this.subscriptionBusinessService.getPublishedPlan(paramsReq);
  }

  getPlanByCliId(paramsReq: object){
    return this.subscriptionBusinessService.getPlanByCliId(paramsReq);
  }
  getPlanById(paramsReq: object) {
    return this.subscriptionBusinessService.getPlanById(paramsReq);
  }
  getFeatureData(): Observable<any> {
    return this.subscriptionBusinessService.getFeatureData();
  }

  getFeatureNameList(): Observable<any> {
    return this.subscriptionBusinessService.getFeatureNameList();
  }
  getPlanList():Observable<any>{
    return this.subscriptionBusinessService.getPlanList();
  }
  getStatusList():Observable<any>{
    return this.subscriptionBusinessService.getStatusList();
  }
  getPricingList(data):Observable<any>{
    return this.subscriptionBusinessService.getPricingList(data);
  }

  updateFeature(data):Observable<any>{
    return this.subscriptionBusinessService.updateFeature(data)
  }

  updateClientwithPlan(data):Observable<any>{
    return this.subscriptionBusinessService.updateClientwithPlan(data)
  }

  createAddFeature(data: any): Observable<any> {
    return this.subscriptionBusinessService.createAddFeature(data);
  }

  getPlanNameByCountry(obj):Observable<any>{
    return this.subscriptionBusinessService.getPlanNameByCountry(obj);
  }
  updatePlan(data): Observable<any> {
    return this.subscriptionBusinessService.updatePlan(data)
  }
  createPlan(data: any): Observable<any> {
    return this.subscriptionBusinessService.createPlan(data);
  }
  getBillingPagination(data): Observable<any> {
    return this.subscriptionBusinessService.getBillingPagination(data);
  }
  getPartPlanInfo(data): Observable<any> {
    return this.subscriptionBusinessService.getPartPlanInfo(data);
  }
  getPlanFeature(data): Observable<any> {
    return this.subscriptionBusinessService.getPlanFeature(data);
  }
  getFeatureAccess(data): Observable<any> {
    return this.subscriptionBusinessService.getFeatureAccess(data);
  }
  downloadBillReport(data): Observable<Blob> {
    return this.subscriptionBusinessService.downloadBillReport(data);
  }
  createCard(data: any): Observable<any> {
    return this.subscriptionBusinessService.createCard(data);
  }
  createSubscription(data: any): Observable<any> {
    return this.subscriptionBusinessService.createSubscription(data);
  }
  getCardDetails(data): Observable<any> {
    return this.subscriptionBusinessService.getCardDetails(data);
  }
  updateAutoRenewal(data: any): Observable<any> {
    return this.subscriptionBusinessService.updateAutoRenewal(data);
  }
  deleteCard(data: any): Observable<any> {
    return this.subscriptionBusinessService.deleteCard(data);
  }
  renewSubscription(data: any): Observable<any> {
    return this.subscriptionBusinessService.renewSubscription(data);
  }
  upgradeSubscription(data):Observable<any>{
    return this.subscriptionBusinessService.upgradeSubscription(data)
  }
  getSubDetailByCli(paramsReq: object){
    return this.subscriptionBusinessService.getSubDetailByCli(paramsReq);
  }
  publishConfirm(data: any){
    return this.subscriptionBusinessService.publishConfirm(data);
  }
}
