import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})

export class FeedbackSnackbarService {
  [x: string]: any;

  constructor(private snackBar: MatSnackBar) { }

  errorSnackbar(errorMsg: string) {
    this.snackBar.open(errorMsg, 'X', {
      verticalPosition: "top",
      panelClass: ['error-snackbar'],
      "duration": 1500
    });
  }

  successSnackbar(successMsg:string){
    this.snackBar.open(successMsg,'X', {
      verticalPosition: "top",
      panelClass: ['success-snackbar'],
      "duration": 1000
    });
  }
}
