import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EncryptDecryptService {
  encryptSecretKey = environment.secretKey;
  encryptData(data) {
    try {
      let encrypted = CryptoJS.AES.encrypt(
        JSON.stringify(data),
        this.encryptSecretKey
      ).toString();
      let encryptedHex = CryptoJS.enc.Base64.parse(encrypted).toString(CryptoJS.enc.Hex);
      return encryptedHex

    } catch (e) { }
  }

  decryptData(encryptedData) {
    try {
      // Decrypt the encrypted data  
      let encryptedBase64 = CryptoJS.enc.Hex.parse(encryptedData).toString(CryptoJS.enc.Base64);
      let decryptedData = CryptoJS.AES.decrypt(encryptedBase64, this.encryptSecretKey).toString(CryptoJS.enc.Utf8);
      // Parse the decrypted JSON string back to an object  
      const planDetail = JSON.parse(decryptedData);
      return planDetail;
    } catch (e) {
      return null;
    }
  }
}
