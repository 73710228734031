import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHelperService } from '../../utilities/http-helper/http-helper.service';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  constructor(private http: HttpClient, private httpHelper: HttpHelperService) { }

  getFeaturePagination(paramsReq: any): Observable<any> {
    let params = new HttpParams()
      .set("pageNumber", paramsReq.pageNumber)
      .set("pageSize", paramsReq.pageSize)
      .set("searchTerm", paramsReq.searchTerm)
    // if (paramsReq.SearchTerm) {
    //   params = params.append('searchTerm', paramsReq.searchTerm);
    // }
    const apiUrl = this.httpHelper.getSubApiUrl('/api/Features/GetFeaturesListWithPagination');
    return this.http.get(apiUrl, { params: params });
  }

  getPlanPagination(paramsReq: any): Observable<any> {
    let params = new HttpParams()
      .set("pageNumber", paramsReq.pageNumber)
      .set("pageSize", paramsReq.pageSize)
      .set("searchTerm", paramsReq.searchTerm)
    const apiUrl = this.httpHelper.getSubApiUrl('/api/Plans/GetPlansListWithPagination');
    return this.http.get(apiUrl, { params: params });
  }

  getPublishedPlan(paramsReq: any): Observable<any> {
    let params = new HttpParams()
      .set("countryId", paramsReq.countryId)
      .set("clientId", paramsReq.clientId)
    const apiUrl = this.httpHelper.getSubApiUrl('/api/Plans/GetPublishedPlans');
    return this.http.get(apiUrl, { params: params });
  }

  getFeatureById(paramsReq: any): Observable<any> {
    const apiUrl = this.httpHelper.getSubApiUrl(`/api/Features/GetFeaturesById`);
    let params = new HttpParams()
      .set('id', paramsReq.id)
    return this.http.get(apiUrl, { params: params });
  }

  getPlanByCliId(paramsReq: any): Observable<any> {
    const apiUrl = this.httpHelper.getApiUrl(`/api/Clients/GetClientPlansById`);
    let params = new HttpParams()
      .set('Id', paramsReq.Id)
    return this.http.get(apiUrl, { params: params });
  }
  getPlanById(paramsReq: any): Observable<any> {
    const apiUrl = this.httpHelper.getSubApiUrl(`/api/Plans/GetPlanDetailsById`);
    let params = new HttpParams()
      .set('id', paramsReq.id)
    return this.http.get(apiUrl, { params: params });
  }

  getFeatureData(): Observable<any> {
    const apiUrl = this.httpHelper.getSubApiUrl(`/api/Features/GetDataTypesByList`);
    let params = new HttpParams()
    return this.http.get(apiUrl, { params: params });
  }

  getFeatureNameList(): Observable<any> {
    const apiUrl = this.httpHelper.getSubApiUrl(`/api/Features/GetFeaturesList`);
    let params = new HttpParams()
    return this.http.get(apiUrl, { params: params });
  }
  getPlanList(): Observable<any> {
    const apiUrl = this.httpHelper.getSubApiUrl(`/api/Plans/GetPlansList`);
    let params = new HttpParams()
    return this.http.get(apiUrl, { params: params });
  }
  getStatusList(): Observable<any> {
    const apiUrl = this.httpHelper.getSubApiUrl(`/api/Subscription/GetStatusList`);
    let params = new HttpParams()
    return this.http.get(apiUrl, { params: params });
  }
  getPricingList(paramsReq: any): Observable<any> {
    const apiUrl = this.httpHelper.getSubApiUrl('/api/Subscription/GetSubscriptionFrequencyList');
    let params = new HttpParams()
      .set("planId", paramsReq.planId)
    return this.http.get(apiUrl, { params: params });
  }

  getPlanNameByCountry(paramsReq: any): Observable<any> {
    const apiUrl = this.httpHelper.getSubApiUrl('api/Plans/GetPlansListByCountry');
    let params = new HttpParams()
      .set("countryId", paramsReq.countryId)
    return this.http.get(apiUrl, { params: params });
  }

  updateFeature(data: any): Observable<any> {
    const apiUrl = this.httpHelper.getSubApiUrl(`/api/Features/UpdateFeature`);
    const requestOptions: Object = {
      responseType: 'text'
    }
    return this.http.put<any>(apiUrl, data, requestOptions);
  }

  updateClientwithPlan(data: any): Observable<any> {
    const apiUrl = this.httpHelper.getApiUrl(`/api/Clients/UpdateClientPlans`);
    const requestOptions: Object = {
      responseType: 'text'
    }
    return this.http.put<any>(apiUrl, data, requestOptions);
  }
  updatePlan(data: any): Observable<any> {
    const apiUrl = this.httpHelper.getSubApiUrl(`/api/Plans/UpdatePlans`);
    const requestOptions: Object = {
      responseType: 'text'
    }
    return this.http.put<any>(apiUrl, data, requestOptions);
  }
  createAddFeature(data: any): Observable<any> {
    const apiUrl = this.httpHelper.getSubApiUrl('/api/Features/CreateFeature');
    const requestOptions: Object = {
      responseType: 'text'
    }
    return this.http.post(apiUrl, data, requestOptions);
  }
  createPlan(data: any): Observable<any> {
    const apiUrl = this.httpHelper.getSubApiUrl('/api/Plans/CreatePlans');
    const requestOptions: Object = {
      responseType: 'text'
    }
    return this.http.post(apiUrl, data, requestOptions);
  }
  getBillingPagination(paramsReq: any): Observable<any> {
    let params = new HttpParams()
      .set("pageNumber", paramsReq.pageNumber)
      .set("pageSize", paramsReq.pageSize)
      .set("searchTerm", paramsReq.searchTerm)
      .set("filterId", paramsReq.filterId)
    const apiUrl = this.httpHelper.getSubApiUrl('/api/Plans/GetBillingListWithPaginationByClientId');
    return this.http.get(apiUrl, { params: params });
  }

  getPartPlanInfo(paramsReq: any): Observable<any> {
    let params = new HttpParams()
      .set("clientId", paramsReq.clientId)
    const apiUrl = this.httpHelper.getSubApiUrl('/api/Plans/GetPlansInformationByClientId');
    return this.http.get(apiUrl, { params: params });
  }

  getPlanFeature(paramsReq: any): Observable<any> {
    let params = new HttpParams()
      .set("ClientId", paramsReq.ClientId)
      .set("planid", paramsReq.planid)
    const apiUrl = this.httpHelper.getSubApiUrl('/api/Features/GetPlanFeaturesByid');
    return this.http.get(apiUrl, { params: params });
  }

  getFeatureAccess(paramsReq: any): Observable<any> {
    let params = new HttpParams()
      .set("clientId", paramsReq.clientId)
    const apiUrl = this.httpHelper.getSubApiUrl('/api/Features/GetFeaturesPermissionByStylistId');
    return this.http.get(apiUrl, { params: params });
  }

  downloadBillReport(data): Observable<Blob> {
    let params = new HttpParams()
      .set("subscriptionId", data.subscriptionId)
    const apiUrl = this.httpHelper.getSubApiUrl('/api/Subscription/DownloadBillingDetalis');
    const requestOptions: Object = {
      responseType: 'blob' as 'json'
    };
    return this.http.get<any>(apiUrl, { params: params, ...requestOptions });
  }

  createCard(data: any): Observable<any> {
    const apiUrl = this.httpHelper.getSubApiUrl('/api/Stripe/CreateCard');
    const requestOptions: Object = {
      responseType: 'text'
    }
    return this.http.post(apiUrl, data, requestOptions);
  }

  createSubscription(data: any): Observable<any> {
    const apiUrl = this.httpHelper.getSubApiUrl('/api/Subscription/CreateSubscription');
    const requestOptions: Object = {
      responseType: 'text'
    }
    return this.http.post(apiUrl, data, requestOptions);
  }

  getCardDetails(paramsReq: any): Observable<any> {
    let params = new HttpParams()
      .set("email", paramsReq.email)
    const apiUrl = this.httpHelper.getSubApiUrl('/api/Stripe/GetCardDetails');
    return this.http.get(apiUrl, { params: params });
  }

  updateAutoRenewal(data: any): Observable<any> {
    const apiUrl = this.httpHelper.getSubApiUrl('/api/Stripe/UpdateAutoRenewal');
    const requestOptions: Object = {
      responseType: 'text'
    }
    return this.http.put(apiUrl, data, requestOptions);
  }

  deleteCard(data: any): Observable<any> {
    const apiUrl = this.httpHelper.getSubApiUrl('/api/Stripe/DeleteCard');
    const requestOptions: Object = {
      responseType: 'text'
    }
    return this.http.post(apiUrl, data, requestOptions);
  }

  renewSubscription(data: any): Observable<any> {
    const apiUrl = this.httpHelper.getSubApiUrl('/api/Subscription/RenewalSubscription');
    const requestOptions: Object = {
      responseType: 'text'
    }
    return this.http.put(apiUrl, data, requestOptions);
  }

  upgradeSubscription(data: any): Observable<any> {
    const apiUrl = this.httpHelper.getSubApiUrl(`/api/Subscription/UpgradeSubscriptions`);
    const requestOptions: Object = {
      responseType: 'text'
    }
    return this.http.post<any>(apiUrl, data, requestOptions);
  }

  getSubDetailByCli(paramsReq: any): Observable<any> {
    const apiUrl = this.httpHelper.getSubApiUrl(`/api/Subscription/ClientSubscriptionPaymentDetails`);
    let params = new HttpParams()
      .set('clientId', paramsReq.clientId)
    return this.http.get(apiUrl, { params: params });
  }

  publishConfirm(data: any): Observable<any> {
    const apiUrl = this.httpHelper.getSubApiUrl(`/api/Plans/CheckPublishPlans`);   
    const requestOptions: Object = {
      responseType: 'text'
    } 
    return this.http.put(apiUrl, data, requestOptions);
  }
}
